import type { GetStaticPropsContext } from 'next';
import Head from 'next/head';
// import Link from 'next/link';
import { useRouter } from 'next/router';
import { useTranslations } from 'next-intl';

import { H1 } from '@fu/ui';

import { queryBuilder } from '@/lib/sanity/query-builder';

export async function getStaticProps({
  params,
  preview = false,
  locale = 'nb',
}: GetStaticPropsContext) {
  const settings = await queryBuilder();
  const messages = (
    (await import(`@/i18n/${locale}.json`)) as {
      default: IntlMessages | undefined;
    }
  ).default;

  return {
    props: {
      preview,
      settings,
      messages,
    },
    revalidate: 60 * 60, // 1 hour
  };
}

interface NotFoundPageProps {
  data: {
    query: string;
    queryParams: { slug: string };
  };
  preview: boolean;
}

/**
 * NotFound page.
 */
export default function NotFound({ data, preview }: NotFoundPageProps) {
  const t = useTranslations();
  const { locale } = useRouter();

  return (
    <>
      <main className="container mx-auto px-4">
        <article className="flex-1-1-auto flex flex-col items-center justify-center">
          <header>
            <H1 size="6xl">404</H1>
          </header>

          <p>These are not the courses you&apos;re looking for</p>
        </article>
      </main>
    </>
  );
}
